<template>
  <div class="edit-promotion">
    <promotion-form v-if="promotion" :usePromotion="promotion" />
  </div>
</template>

<script>
import PromotionForm from "@/components/promotion-form";

export default {
  name: "edit-promotion",
  components: {
    PromotionForm
  },
  data() {
    return {
      promotion: null
    };
  },
  mounted() {
    this.getPromotion();
  },
  methods: {
    async getPromotion() {
      this.ebpLoader(true);

      try {
        const res = await this.$store.dispatch(
          "promotions/show",
          this.$route.params.id
        );
        this.promotion = res.data;
      } catch (err) {
        console.error(err);
      }

      this.ebpLoader(false);
    }
  }
};
</script>

<style></style>
